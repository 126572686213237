import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { useSession } from 'src/context/SessionContext';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useResource } from 'src/hooks/useResource';
import { DUPWizard } from 'src/pages/DUPPage/DUPWizard';
import { DupApplicationType, SessionApplication } from 'src/types/api';
import { DUPPage } from './DUPPage';

export const DUPPageProvider = ({ type }: { type: DupApplicationType }) => {
  const { propertyId } = useParams();
  const { sessionToken, resetSession, applicantIdentifier } = useSession();

  const {
    onGetFeatureFlagValue,
    loading,
    data: isNewDUPExperienceActiveFF
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.applicantDupExperienceRedesign,
    filter: `propertyId=${propertyId}`
  });

  const [application, { put, refresh }] = useResource<SessionApplication | { error: string }>(
    `/session/application`,
    { silent: true }
  );

  const getFeatureFlagValue = useCallback(() => {
    onGetFeatureFlagValue();
  }, [onGetFeatureFlagValue]);

  useEffect(() => {
    if (application) {
      if ('error' in application) {
        datadogLogs.logger.info('Error in application object for session', { application });
        resetSession();
      } else if (
        // an applicant could have applications at multiple properties, so we're just making sure we are showing the correct one
        application?.propertyShortId !== propertyId
      ) {
        datadogLogs.logger.info(
          `Property ID does not match application: 
          From Param: ${propertyId}
          From application: ${application.propertyShortId}`,
          {
            application
          }
        );
        resetSession();
      } else if (
        // ensuring that the application is for the correct applicant
        applicantIdentifier &&
        applicantIdentifier !== application.applicantDetailId
      ) {
        datadogLogs.logger.info(
          `Applicant identifier does not match application:
           From URL param: ${applicantIdentifier}
           From application object: ${application.applicantDetailId}`,
          { application }
        );
        resetSession();
      }
    }

    if (sessionToken && isNewDUPExperienceActiveFF === null) {
      getFeatureFlagValue();
    }
  }, [
    sessionToken,
    application,
    propertyId,
    applicantIdentifier,
    isNewDUPExperienceActiveFF,
    getFeatureFlagValue,
    refresh,
    resetSession
  ]);

  if (
    loading ||
    !application ||
    'error' in application ||
    application?.propertyShortId !== propertyId ||
    // Don't bother loading the page if we don't have a session token yet
    Boolean(sessionToken) === false
  ) {
    return <Loader isFixed />;
  }

  const onUpdateApplication = async (updates: Partial<SessionApplication>) => {
    await put(updates);
    return await refresh(sessionToken);
  };

  return isNewDUPExperienceActiveFF && type === DupApplicationType.UNAUTHENTICATED_USER ? (
    <DUPWizard type={type} application={application} onUpdateApplication={onUpdateApplication} />
  ) : (
    <DUPPage type={type} application={application} onUpdateApplication={onUpdateApplication} />
  );
};
